export const basePath = "/performance"

/**
 * A list of every route used in the perform website.
 *
 * Please do not use strings as routes, or use string concatenation
 * for deriving your route. Treat them as if their values were some random,
 * unknown number. The purpose of this enum is to make it
 * very easy to trace where and how a route is used. If you're asking "Where
 * is this route getting used?", you can simply call "find usages" in your
 * IDE on the enum value, or do a global search for R.myRouteName.
 *
 * Note: this list is not complete yet. There is a tech debt task to go through
 * the existing code here:
 *   https://trello.com/c/wbaKt7ze/27-use-a-constants-file-for-route-addresses
 */
enum R {
  adminAdvanced = "/admin/advanced_admin",
  adminEcIndividualStats = "/admin/evaluation_cycles/:id/pauf_requests/:employeeId",
  adminSubjectFeedbackOverview = "/admin/performance_review_cycle/:evaluationCycleId/pauf_requests/:subjectId",
  adminEcDashboard = "/admin/evaluation_cycles/:id",
  adminEcListing = "/admin/evaluation_cycles",
  adminEditSavedView = "/admin/evaluation/saved_view/:savedViewId/edit",
  adminShowSavedView = "/admin/evaluation/saved_view/:savedViewId",
  adminEmployeeEvaluationsListing = "/admin/evaluation", // Legacy admin: employee evaluations listing page
  adminEmployeeEvaluationsDetails = "/admin/evaluation/:id/:slug/", // Legacy admin: employee evaluations details page yes, that trailing slash is required for some reason
  adminEvaluation = "/admin/evaluation",
  adminEvaluationAll = "/admin/evaluation/all",
  adminCalibrationSavedViewManagerReview = "/admin/evaluation/saved_view/:savedViewId/:cycleId/:cycleName?/review/:reviewId",
  adminFeedbackSettings = "/admin/manager_request_feedback_settings",
  adminFeedbackStats = "/admin/feedback/stats",
  adminGoals = "/admin/goals/individual",
  adminGoalsStats = "/admin/goals/stats",
  adminOneOnOnesUsageReport = "/admin/1-1s_stats",
  adminPrinterFriendlyEvaluation = "/admin/printer_friendly_evaluation",
  adminPerformanceReview = "/admin/evaluation/:id/:slug/review/:reviewId",
  adminRequestFeedback = "/admin/request_feedback",
  adminSearch = "/admin/search",
  adminSelfReflection = "/admin/self_reflection",
  adminSkills = "/admin/skills",
  adminTeamFeedback = "/admin/team_feedback/history",
  calibrationSavedViews = `/evaluation/saved_views/:savedViewId`,
  calibrationViewsAll = "/admin/evaluation/all",
  companyDepartmentGoals = "/goals/department",
  companyDirectory = "/search",
  companyGoals = "/goals/company",
  companyPraise = "/public_praise",
  companyTeams = "/teams",
  companyArchivedTeams = "/teams/archived",
  dashboard = "/", // Deprecated: Please use R.home or R.notifications
  ecListing = "/evaluation_cycles",
  ecDetails = "/evaluation_cycles/:id",
  ecPaufRequestFeedback = "/evaluation_cycle/:id/peer_and_upward_feedback/request_feedback",
  ecMlRequestedPeerFeedback = "/feedback/requests/:evaluationCycleId/:subjectId",
  ecSharingPreview = "/evaluation_cycles/:evaluationCycleId/sharing_preview/:subjectId",
  ecShareIndividualFeedback = "/dashboard/evaluation_cycles/:evaluationCycleId/subject_sharing_states/:id/share_feedbacks",
  ecShareIndividualReview = "/dashboard/performance_cycles/:performanceCycleId/performance_reviews/:performanceReviewId",
  ecFeedback = "/evaluation_cycles/:evaluationCycleId/feedbacks",
  ecFeedbackSubject = "/evaluation_cycles/:evaluationCycleId/feedbacks/:subjectId",
  ecManagerReviews = "/evaluation_cycles/:evaluationCycleId/manager_reviews",
  employeeEvaluationsListing = "/evaluation", // Legacy employee evaluations review
  employeeEvaluationsDetail = "/evaluation/:id", // Legacy employee evaluations detail
  employeeEvaluationsReview = "/evaluation/:id/:slug/review/:reviewId", // Legacy employee evaluations review
  goals = "/new_goals/personal",
  home = "/home",
  unifiedHome = "/app/home",
  inbox = "/notifications", // Deprecated: Please use R.home or R.notifications
  legacyGoals = "/goals",
  playbooks = "/app/playbooks/?context=performance",
  skillsCoach = "/app/skills-coach/?context=performance",
  managerLabApi = "/api/manager-lab",
  murmurApi = "/api/murmur",
  managerRequestedFeedback = "/manager_requested_feedback",
  meDevelopment = "/development",
  meFeedback = "/myfeedback",
  meGoals = "/goals/personal",
  meSelfReflection = "/self_reflections_overview",
  meSharedEvaluation = "/shared_evaluations_overview",
  meSummary = "/summary",
  myProfile = "/profile",
  notifications = "/notifications",
  oneOnOne = "/1-1",
  oneOnOneSpace = "/1-1/:oneOnOneUuid",
  printerFriendlyEvaluation = `/printer_friendly_evaluation`,
  requests = "/requests",
  review = "/review",
  selfReview = "/self_review",
  sharedEvaluationsOverviewPage = "/shared_evaluations_overview",
  sharedEcListingPage = "/evaluation_cycles/shared",
  sharedEcDetailPage = "/evaluation_cycles/shared/:cycleId",
  sharedEcDetailFeedbackPage = "/evaluation_cycles/shared/:cycleId/feedbacks",
  sharedEcDetailManagerReviewsPage = "/evaluation_cycles/shared/:cycleId/manager_reviews",
  summaryEvaluationCyclePage = "/evaluation_cycles/summary",
  summaryEvaluationCycleDetailsPage = "/evaluation_cycles/summary/:cycleId",
  selfReflectionsPage = "/self_reflections/overview",
  savedViews = "/evaluation/saved_views/:savedViewId",
  calibrationSavedViewManagerReview = "/evaluation/saved_views/:savedViewId/:cycleId/:cycleName?/review/:reviewId",
  teamBasedFeedback = "/team_based_feedback",
  teamEvaluation = "/evaluation",
  teamFeedback = "/team_feedback",
  teamFeedbackRequest = "/team_feedback_request",
  teamGoals = "/goals/team",
  teamSelfReflection = "/team_self_reflection",
  teamSummary = "/team_summary",
  teamTeams = "/team_search",
  teamUsage = "/team_usage",
  error403 = "/403",
  error404 = "/404",
}

export default R
