import { useQuery } from "@tanstack/react-query"
import {
  isErrorWithResponse,
  perfApiGet,
  perfApiPut,
  ResponseError,
} from "@API/utils"
import currentUserDecoder from "@API/decoders/CurrentUserDecoder/currentUserDecoder"
import { captureDecoderException } from "@API/decoders/utils"
import endpoints from "@Constants/endpointsDeprecated"
import { CurrentUser } from "@Types/User"
import { getLocalTimezone } from "@Utils/date"
import { sendErrorToDatadog } from "@Common/utils"

const { USERS_URLS } = endpoints
const { CURRENT_USER_URL } = USERS_URLS

const CURRENT_USER_QUERY_KEY = "common.user.me"
const USER_QUERY_KEY = "common.user"

class CurrentUserFetchError extends Error {
  message: "Could not fetch current user from the server"
}

export const useCurrentPerformanceUser = () =>
  useQuery<CurrentUser | null>(
    [CURRENT_USER_QUERY_KEY],
    async () => {
      let userResponse: CurrentUser
      let user: CurrentUser

      try {
        const res = await perfApiGet<{ user: CurrentUser }>(CURRENT_USER_URL)
        userResponse = res.data.user
      } catch (error: unknown) {
        if (isErrorWithResponse(error)) {
          if (error.response.status === 403) {
            return null
          }
          throw new CurrentUserFetchError()
        }
        throw error
      }

      try {
        user = await currentUserDecoder.decodePromise({
          user: userResponse,
        })
      } catch (error) {
        captureDecoderException(error)
        throw new Error("Failed to decode user from server")
      }

      try {
        if (!user.is_masquerading) {
          // Save the time zone so that we can send emails at an appropriate time
          // It's okay if this call occasionally fails silently, we can observe
          // the failure via datadog
          const timeZone = getLocalTimezone()
          if (user.time_zone !== timeZone) {
            perfApiPut(CURRENT_USER_URL, {
              body: { user: { time_zone: timeZone } },
            }).catch(() => {})
          }
        }
      } catch (error) {
        sendErrorToDatadog(error)
      }

      return user
    },
    {
      // These are cleared on refresh.
      // If the session is no longer valid any API call will return a 401
      // and the user will be redirected to the sign in page
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: (count, error) => {
        return error instanceof CurrentUserFetchError && count < 2
      },
      retryDelay: 1000,
    }
  )

export type UserProfile = {
  id: number
  full_name: string
  profile_image_url: string
  job_title_name?: string
  deactivated_at: string | null
}

export const usePerformanceUserProfile = (userId: number) =>
  useQuery<UserProfile, ResponseError | Error | undefined>(
    [USER_QUERY_KEY, userId],
    () =>
      perfApiGet<{ user: UserProfile }>(
        `/users/${encodeURIComponent(userId)}`
      ).then((resp) => resp.data.user)
  )
