export enum LaunchDarklyFeatureFlags {
  evaluationCyclesMultilingualCycles = "evaluation-cycles.multilingual-cycles",
  performanceMaintenanceWindow = "performance.maintenance-window",
  recognizeFeedDossierEnable = "recognize.feed.dossier.enable",
  recognizeFeedDossierFilterEnable = "recognize.feed.dossier.filter.enable",
  performanceManagerEvaluationQuestionsPageUpliftEnable = "performance.manager-evaluation-questions-page-uplift.enable",
  performanceAdminCycleSharingUpliftEnable = "performance.admin-cycle-sharing-uplift.enable",
  performanceCalibrationsUpliftEnable = "performance.calibrations-uplift.enable",
  performanceCollaboratorCommentsUpliftEnable = "performance.collaborator-comments-uplift.enable",
  performanceSelfReflectionsRebuild = "performance.self-reflections-rebuild",
}

export type EnumFlagValues = {
  [key in keyof typeof LaunchDarklyFeatureFlags]: LaunchDarklyFlagsAndVariations[(typeof LaunchDarklyFeatureFlags)[key]]
}

export type LaunchDarklyFlagsAndVariations = {
  [LaunchDarklyFeatureFlags.evaluationCyclesMultilingualCycles]:
    | "NONE"
    | "MANAGER_REVIEW"
    | "MANAGER_REVIEW_AND_PEER_FEEDBACK"
  [LaunchDarklyFeatureFlags.performanceMaintenanceWindow]: true | false
  [LaunchDarklyFeatureFlags.recognizeFeedDossierEnable]: true | false
  [LaunchDarklyFeatureFlags.recognizeFeedDossierFilterEnable]: true | false
  [LaunchDarklyFeatureFlags.performanceManagerEvaluationQuestionsPageUpliftEnable]: boolean
  [LaunchDarklyFeatureFlags.performanceAdminCycleSharingUpliftEnable]:
    | true
    | false
  [LaunchDarklyFeatureFlags.performanceCalibrationsUpliftEnable]: true | false
  [LaunchDarklyFeatureFlags.performanceCollaboratorCommentsUpliftEnable]:
    | true
    | false
  [LaunchDarklyFeatureFlags.performanceSelfReflectionsRebuild]:
    | "V1"
    | "MIGRATING"
    | "V2"
}

export default LaunchDarklyFeatureFlags
